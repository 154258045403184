import React from 'react';

export default function ContactNumbers({ }) {
  return (
    <>
      <p>
        Emergency Out of Hours Repairs - Telephone: <strong>01522 534747</strong>
      </p>
      <p>
        Emergency In Hours Repairs - Telephone:  <strong>01522 873333</strong>
      </p>
    </>
  )
}


